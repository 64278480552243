.grid{
    display: grid;
}


.verticalEnd{
    align-items: end;
    align-content: end;
}
.verticalCenter{
    align-items: center;
    align-content: center;
}

.horizontalCenter{
    justify-items: center;
    justify-content: center;
}
.horizontalEnd{
    justify-items: end;
    justify-content: end;
}

.separaVerticalmente{
    align-content: space-between;
}
.separaHorizontalmente{
    justify-content: space-between;
}

.separaVerticalUniforme{
    align-content: space-evenly;
}
.separaHorizontalUniforme{
    justify-content: space-evenly;
}

.container{
    display: grid;
}
@media (max-width: 600px) {
    .container {
        grid-template-columns: 100%;
    }
    .wrapper {
        grid-column: 1/2;
        padding: 20px;
    }
    .fullWidth{
        width: calc(100vw - 2px);
    }
}

@media (min-width: 601px) AND (max-width: 1199px) {
    .container {
        grid-template-columns: minmax(1em, 1fr) minmax(0, 80em) minmax(1em, 1fr);
    }
    .wrapper {
        grid-column: 2/3;
    } 
    .fullWidth{
        width: calc(100vw - 2px);
    }
}

@media (min-width: 1200px) {
    .container {
        grid-template-columns: minmax(1em, 1fr) minmax(0, 80em) minmax(1em, 1fr);
    }
    .wrapper {
        grid-column: 2/3;
    }
    .fullWidth{
        width: calc(100vw - 10px);
    }
}


.pointer{
    cursor: pointer;
}



img{
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
}


.sectionPadding{
    padding: 48px 0px;
}

body{
    background-color: #fff;
}





@media (max-width: 600px) {
    .hideMobile{
        display:none;
    }
}

@media (min-width: 601px) AND (max-width: 1199px) {
}

@media (min-width: 1200px) {
}
@import '../style/color.style.scss';

.grid {
  display: grid;
}

//VERTICAL
.verticalCenter {
  align-content: center;
  align-items: center;
}
.verticalEvenly {
  align-content: space-evenly;
}
//HORIZONTAL
.horizontalCenter {
  justify-content: center;
  justify-items: center;
}
.horizontalStart {
  justify-content: start;
}
.endHorizontal {
  justify-content: end;
}
.separaHorizontal {
  justify-content: space-between;
}

//FUENTES
.raleway {
  font-family: "Raleway", sans-serif;
}
.roboto {
  font-family: "Roboto", sans-serif;
}

.ralewayI {
  font-family: "Raleway", sans-serif !important;
}
.robotoI {
  font-family: "Roboto", sans-serif !important;
}

//WEIGHTS
.weight100 {
  font-weight: 100;
}
.weight200 {
  font-weight: 200;
}
.weight300 {
  font-weight: 300;
}
.weight400 {
  font-weight: 400;
}
.weight500 {
  font-weight: 500;
}
.weight600 {
  font-weight: 600;
}
.weight700 {
  font-weight: 700;
}
.weight800 {
  font-weight: 800;
}
.weight900 {
  font-weight: 900;
}

//TAMAÑOS
.tamano12 {
  font-size: 12px;
}

//pointer
.pointer {
  cursor: pointer;
}
.centraTexto {
  text-align: center;
}

//img
img {
  width: 100%;
  height: auto;
}
p {
  margin: 0 !important;
}



//COLORES TEXTO

.negro {
  color: #141414;
}

.azulOscuro {
  color: #04424e;
}
.verdePrimario {
  color: #91d600;
}
.verdeSecundario {
  color: #ace732;
}
.blancoPrimario {
  color: #f5f5f5;
}
.blancoSecundario {
  color: #fffcff;
}

//button

.button {
  padding: 11px 32px;
  text-align: center;
  border: thin solid #04424e;
  color: #04424e;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 300;
  font-family: "roboto", sans-serif;
}

.buttonWhite {
  border: thin solid !important;
  color: $blanco !important;
}

.button:hover {
  color: #ffffff;
  background-color: #04424e;
}

//FILTROS

.filtroVerde {
  filter: invert(80%) sepia(18%) saturate(4575%) hue-rotate(30deg)
    brightness(96%) contrast(101%);
}

.filtroAzul {
  filter: invert(20%) sepia(11%) saturate(5670%) hue-rotate(152deg)
    brightness(92%) contrast(97%);
}

body #magicPointer {
  background-color: #04424e !important;
  z-index: 9999;
}

body #magicMouseCursor {
  border-color: #04424e !important;
}

@media (max-width: 600px) {
  .calendly > .modal-wrapper {
    height: 100vh;
    width: 100vw;
  }
}
@media (min-width: 601px) AND (max-width: 1200px) {
  .calendly > .modal-wrapper {
    height: 100vh;
    width: 100vw;
  }
}
@media (min-width: 1201px) {
  .calendly > .modal-wrapper {
    height: 692px;
    border-radius: 8px;
    width: 1000px;
  }
}


.fullPage {
  width: 100vw;
  height: 100vh;
  padding: 40px 100px;
}

@media (max-width: 600px) {
  .fullPage {
    padding: 10px 25px;
  }
}
@media (min-width: 601px) AND (max-width: 1200px) {
	.fullPage {
    padding: 20px 50px;
  }
}
@media (min-width: 1201px) {
	.fullPage {
    padding: 40px 100px;
  }
}


body{
  background-color: #fffcff;
}
.filterGrey{
    filter: invert(98%) sepia(79%) saturate(72%) hue-rotate(257deg) brightness(116%) contrast(92%);
}


.filterBlue{
    filter: invert(9%) sepia(77%) saturate(771%) hue-rotate(148deg) brightness(95%) contrast(98%);
}

.filtroBlanco{
    filter: invert(97%) sepia(18%) saturate(259%) hue-rotate(237deg) brightness(113%) contrast(92%);
}

.filtroGrisOscuro{
    filter: invert(17%) sepia(0%) saturate(337%) hue-rotate(281deg) brightness(93%) contrast(87%);
}
//NO SE SI ESTA TRATADO
.filterWhiteProv{
    filter: invert(100%) sepia(2%) saturate(21%) hue-rotate(187deg) brightness(116%) contrast(93%);
}

.filterWhiteProv:hover {
    filter: invert(73%) sepia(35%) saturate(325%) hue-rotate(356deg) brightness(94%) contrast(88%);
}

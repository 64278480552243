@import './color.style.scss';

$tamano12: 12px;
$tamano15: 15px;
$tamano23: 23px;
$tamano32: 32px;
$tamano38: 38px;
$tamano51: 51px;

// TAMAÑOS
.tamano12{
    font-size: 12px;
}
.tamano15{
    font-size: 15px;
}
.tamano23{
    font-size: 23px;
}
.tamano32{
    font-size: 32px;
}
.tamano38{
    font-size: 38px;
}
.tamano51{
    font-size: 51px;
}


// COLOR 
.negro{
    color: $negro !important;   
}

.negroGris{
    color: $negroGris !important;   
} 

.azul{
    color: $azul !important;   
}
.azulOscuro{
    color: $azulOscuro !important;   
}
.verde{
    color: $verde !important;   
}
.gris{
    color: $gris !important;   
}
.blanco{
    color: $blanco !important;   
}


// ALINEACIONES 
.endTexto{
    text-align: end;
}
.centraTexto{
    text-align: center;
}

.borderTextBottom{
    text-decoration: underline;
    text-underline-offset: 12px;
    margin-bottom: 12px !important;
}


// TEXTOS


.superTitulo{
    color: $negro;
    font-size: $tamano51;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    margin: 0;
}

.titulo{
    color: $verde;
    font-size: $tamano38;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    margin: 0;
}

.subtitulo{
    color: $blanco;
    font-size: $tamano23;
    font-family: "Roboto", sans-serif;
    margin: 0;
}
.texto{
    color: $blanco;
    font-size: $tamano15;
    font-family: "Roboto", sans-serif;
    margin: 0;
}
.textoPeque{
    color: $blanco;
    font-size: $tamano12;
    font-family: "Roboto", sans-serif;
    margin: 0;
}

.espacioTexto{
    margin: 16px 0px;
}
.weight300{
    font-weight: 300;
}

.weight400{
    font-weight: 400;
}
.weight500{
    font-weight: 500;
}
.weight600{
    font-weight: 600;
}
.weight700{
    font-weight: 700;
}
.weight800{
    font-weight: 800;
}
.weight900{
    font-weight: 900;
}


.weight300I{
    font-weight: 300 !important;
}

.weight400I{
    font-weight: 400 !important;
}
.weight500I{
    font-weight: 500 !important;
}
.weight600I{
    font-weight: 600 !important;
}
.weight700I{
    font-weight: 700 !important;
}
.weight800I{
    font-weight: 800 !important;
}
.weight900I{
    font-weight: 900 !important;
}


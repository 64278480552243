input{
    border: 1px solid $gris;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
    width: 100%;
    background-color: $blanco;
    color: $azul;
    font-size: 12px;
    
}
textarea{
    border: 1px solid $gris;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
    width: 100%;
    background-color: $blanco;
    color: $azul;
    font-size: 12px;
    
}